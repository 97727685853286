import React, { useCallback, useEffect, useRef, useState } from 'react';
import InputMask from 'react-input-mask';

import { getClientCookie, setClientCookie } from '~/app/lib/utils/cookie';
import { selectUserCountry } from '~/app/lib/store/session/selectors';
import { AddonTypes } from '~/app/lib/songwhipApi/types';
import { useSelector } from '~/app/lib/store/redux';
import useTheme from '~/app/lib/hooks/useTheme';
import { DOB_COOKIE_NAME } from '~/config';
import { useI18n } from '~/app/lib/i18n';

import {
  getDateFromString,
  isDateStringValid,
  resolveAgeFromTimestamp,
} from '~/app/lib/utils/date';

import PrimaryButton from '../Button/PrimaryButton';
import Form, { FormApi } from '../Form';
import TextInput from '../TextInput';
import Text from '../Text';
import Box from '../Box';

import { SelectedCustomPage, SelectedItem } from '~/app/lib/store/types';
import { DEFAULT_TERRITORY_CODE } from './ItemPageEdit/addons/ageGate';
import { ItemBlockedOverlay } from './ItemBlockedOverlay';
import { ItemBlockedText } from './ItemBlockedText';
import { useItemContext } from './ItemPageContext';

const GAP = '2.63rem';

const DateInput = React.memo(
  ({
    isInvalid,
    format,
    value,
    onChange,
  }: {
    isInvalid: boolean;
    format: string;
    value: string;
    onChange(value: string): void;
  }) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const { t } = useI18n();
    const theme = useTheme();

    // NOTE: We have to take input value from the ref to make sure we get
    // the final modified masked value
    const onChangeInternal = useCallback<() => void>(() => {
      if (inputRef.current) {
        onChange(inputRef.current.value);
      }
    }, []);

    // HACK: We need to fire blur event explicitly cause
    // input mask throw an error in case we submit form by pressing enter
    useEffect(() => {
      return () => {
        inputRef.current?.blur();
      };
    }, []);

    return (
      <Box flexColumn>
        <InputMask
          mask="99/99/9999"
          maskPlaceholder={format}
          placeholder={format}
          onChange={onChangeInternal}
          value={value}
        >
          <TextInput
            inputRef={inputRef}
            testId="birthDate"
            withShadow={false}
            name="date"
            required
          />
        </InputMask>
        {isInvalid && (
          <Text
            testId="ageGateDateError"
            color={theme.colorDanger}
            margin="0.44rem 0 0"
            size="1.2rem"
          >
            {t('item.ageGate.dateError')}
          </Text>
        )}
      </Box>
    );
  }
);

const ItemAgeGate: React.FC = React.memo(() => {
  // we show loading state initially cause we do not know
  // during SSR if the user pass age gate or not
  const [isLoading, setIsLoading] = useState(true);
  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [userAge, setUserAge] = useState<number>();
  const [birthday, setBirthday] = useState('');

  const country = useSelector(selectUserCountry);
  const { addons, data } = useItemContext();
  const formApiRef = useRef<FormApi>(null);
  const theme = useTheme();
  const { t } = useI18n();

  const isUS = country === 'US';
  const dateFormat = isUS ? 'MM/DD/YYYY' : 'DD/MM/YYYY';

  const ageLimit =
    addons[AddonTypes.AGE_GATE]?.[country] ??
    addons[AddonTypes.AGE_GATE]?.[DEFAULT_TERRITORY_CODE];

  const isHidden =
    // we do not show age gate for users that can edit the page
    data.item.userCanEdit ||
    ageLimit === undefined ||
    (userAge !== undefined && userAge >= ageLimit);

  // NOTE: Read birthday cookie and set initial age
  useEffect(() => {
    const timestamp = Number(getClientCookie(DOB_COOKIE_NAME));
    const age = resolveAgeFromTimestamp(timestamp);

    setUserAge(age);
  }, []);

  useEffect(() => {
    // if userCanEdit does not exist it means the user data still loading
    // and we cannot determine whether user can edit the page
    // but before knowing that we do not want to show age gate content
    if (data.item.userCanEdit !== undefined) {
      setIsLoading(false);
    }
  }, [data.item.userCanEdit]);

  if (isHidden) return null;

  const renderContent = () => {
    const isBlocked = userAge !== undefined && userAge < ageLimit;

    if (isBlocked) {
      return (
        <ItemBlockedText
          text={t('item.ageGate.error', { age: ageLimit })}
          textColor={theme.colorDanger}
        />
      );
    }

    return (
      <Form<{ date: string }>
        flexColumn
        style={{ gap: GAP }}
        apiRef={formApiRef}
        onSubmit={({ values: { date: dateValue } }) => {
          const date = getDateFromString(dateValue, dateFormat);

          const isDateValid = isDateStringValid(dateValue, dateFormat, [
            new Date(1900, 0, 1).getTime(),
            Date.now(),
          ]);

          if (isDateValid && date) {
            const timestamp = date.getTime();
            const age = resolveAgeFromTimestamp(timestamp);

            setClientCookie(DOB_COOKIE_NAME, timestamp, 30);
            setUserAge(age);
          } else {
            setIsFormInvalid(true);
          }
        }}
      >
        <Box flexColumn style={{ gap: '1rem' }}>
          <Text centered size="3.1rem">
            {t('item.ageGate.title')}
          </Text>
          <Text centered size="1.7rem" color="#999">
            {t('item.ageGate.subtitle', {
              artistName: resolveArtistName(data.item),
            })}
          </Text>
        </Box>
        <DateInput
          format={dateFormat}
          value={birthday}
          isInvalid={isFormInvalid}
          onChange={useCallback((value) => {
            setIsFormInvalid(false);
            setBirthday(value);
          }, [])}
        />
        <PrimaryButton
          testId="submitAgeGate"
          text={t('item.ageGate.submit')}
          height="4.8rem"
          isSubmit
          isDisabled={!/^\d{2}\/\d{2}\/\d{4}$/.test(birthday)}
        />
      </Form>
    );
  };

  return (
    <ItemBlockedOverlay testId="ageGate" isLoading={isLoading}>
      {renderContent()}
    </ItemBlockedOverlay>
  );
});

const resolveArtistName = (item: SelectedItem | SelectedCustomPage) => {
  if ('artistName' in item) {
    return item.artistName;
  }

  return item.name;
};

export default ItemAgeGate;
