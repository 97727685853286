import { FC } from 'react';

import useTheme from '~/app/lib/hooks/useTheme';
import Button, { ButtonProps } from '.';

export interface PrimaryButtonProps extends Omit<ButtonProps, 'isInverted'> {}

const PrimaryButton: FC<PrimaryButtonProps> = (buttonProps) => {
  const theme = useTheme();

  return (
    <Button
      {...buttonProps}
      isUppercase
      textProps={{
        weight: 'medium',
      }}
      style={{
        background: theme.accentColor,
        color: theme.textColorOnAccent,
        boxShadow: '0 0.1em .2em rgba(0,0,0,0.3)',
        border: 'none',
        ...buttonProps.style,
      }}
    />
  );
};

export default PrimaryButton;
